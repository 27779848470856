.chat {
    &.chat-container {
        position: fixed;
        z-index: 99998;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-grow: 1;
        flex-shrink: 1;
        height:  3rem;

        .channel {
            width: 100%;
            transition: transform 300ms linear;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            &.private {
                max-width: 320px;

                width: 100%;
                margin-right: 1rem;
                bottom: 0;
                max-height: 3;

                .body {
                    height: 300px;
                }
            }

            &.public {
                max-width: 420px;
                min-width: 320px;
                height: 80vh;
                
                @media (min-width:1024px) {
                    max-width: 360px;
                    transition: transform 300ms linear, height 300ms linear;
                }
                @media (min-width:1081px) {
                    height: 100vh;
                }
            }

            &.collapse {
                display: block;
                transform: translateY(calc(100% - 3rem));
                overflow: hidden;
                height: 48px!important;

                @media (min-width:1024px) {
                    transition: transform 300ms linear, height 300ms linear;
                }
            }
        }


        header {
            background-color: var(--color-brand);
            color: var(--color-brand-text);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1rem;
            min-height: 3rem;
            height: 3rem;
            cursor: pointer;
            &.notification{
                animation-duration: 1s;
                animation-name: changeColor;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }

            .text {
                font-weight: 700;
            }

            .expand {
                height: 100%;
                display: flex;
                align-items: center;
            }
        }

        .body {
            background-color: white;
            height: calc(100% - 11rem);
            overflow-y: auto;
            padding: 0rem 0.5rem;

            &::-webkit-scrollbar {
                display: none;
            }

            .message {
                border: #e6e6e6 1px solid;
                display: flex;
                flex-direction: column;
                margin: 0.5rem 0;
                padding: 0.5rem;
                max-width: 90%;
                margin-left: 10%;

                &.me {
                    margin-left: 0%;
                    position: relative;
                    border: none;

                    &::after {
                        background-color: var(--color-brand);
                        position: absolute;
                        content: '';
                        opacity: 0.06;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                .name {
                    font-size: 0.8rem;
                    font-weight: bold;
                    margin-bottom: 0.25rem;
                    .me{
                        color:var(--color-accent);
                        text-decoration: underline;
                    }
                }

                .text {
                    font-size: 0.875rem;
                }

                .timestamp {
                    align-self: flex-end;
                    font-size: 0.75rem;
                }
            }

        }

        .input {
            background-color: #f1f1f1;
            padding: 0.1rem;

            .input-message {
                background-color: white;
                border: 1px solid #f1f1f1;
                padding: 0.75rem 1rem;
                width: 100%;
                color: black;
                max-height: 16rem;
                overflow-y: auto;
                cursor: text;

                &:empty::after {
                    content: 'Escribe aquí tu mensaje';
                    color: #999;
                }
            }

            button.emojis {
                color: #666;
                cursor: pointer;
                transition: color 200ms linear;

                &:hover {
                    color: #222;
                }
            }

            .menu-emojis {
                background: white;
                max-height: 10rem;
                height: 10rem;
                overflow: auto;
                padding: 1rem;
                transition: height 200ms linear, padding 0ms 0ms linear;
                border: 1px solid #f1f1f1;
                font-size: 1.5rem;

                &.collapse {
                    display: block;
                    height: 0;
                    overflow: none;
                    padding: 0;
                    transition: height 200ms linear, padding 0ms 199ms linear;
                }
            }

            button.btn-submit {
                font-size: 0.75rem;
            }


        }

        .private {
            display: inline-block;
            position: relative;
            z-index: 9999;
            background: white;
        }
    }

    select#recipient {
        background: transparent;
        color: var(--color-brand);
        padding: 0.5rem;
        font-size: 12px;
        height: auto;
        cursor: pointer;

        option {
            font-size: 1rem;
            color: #222;
        }
    }
}
.wrapper.wrapper-streaming {
    overflow: hidden;
    @media(min-width:480px){
        overflow: auto;
    }
    @media(min-width: 1025px){
        transition: width 300ms 300ms linear;
             width: calc(100% - 360px);
    }
 }


 @keyframes changeColor {
    0% {
       background: var(--color-brand);
    }

    100% {
       background: var(--color-accent);
    }
}