a {
    text-decoration: none;
    color: var(--color-brand);

    &:hover {
        text-decoration: underline;
        color: var(--color-brand);
    }

    &:not([href]):not([tabindex]).btn.primary {
        color: var(--color-accent-text);
    }

    &:not([href]):not([tabindex]).btn.secondary {
        color: var(--color-brand-text);
    }
}

.btn {
    padding: 0.75rem 1.25rem;
    border: 0px;
    border-radius: var(--border-radius);
    transition: filter 200ms linear;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        filter: brightness(0.9);
        text-decoration: none;
    }



    &:focus {
        box-shadow: unset;
    }

    &:active {
        box-shadow: unset;
    }


    &.btn-primary,
    &.primary {
        background-color: var(--color-accent);
        color: var(--color-accent-text);

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
            filter: brightness(0.7);
            background-color: var(--color-accent);
            box-shadow: unset;
        }
    }

    &.btn-plain {
        border: unset;
        background-color: transparent;
        font-weight: 700;
        color: var(--color-brand);
        transition: backdrop-filter 200ms linear;

        &:hover {
            filter: unset;
            backdrop-filter: brightness(95%);
        }
    }

    &.btn-secondary,
    &.secondary {
        background: var(--color-brand);
        color: var(--color-brand-text);

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
            filter: brightness(0.7);
            background-color: var(--color-brand);
            box-shadow: unset;
        }
    }

    &.btn-play {
        display: flex;
        border-radius: 9px;
        transform: scale(2);
    }

}